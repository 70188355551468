/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import Slider from "react-slick";
import NProgress from "nprogress";
import axios from "axios";
import { Row, Col, Input } from "antd";
import { List, Toast, DatePicker, ImageUploader, ImageViewer } from "antd-mobile";
import Hammer from "react-hammerjs";

import Single3 from "../image/Single3.png";
import Single1 from "../image/Single1.png";
import Single2 from "../image/Single2.png";
import Score1 from "../image/Score1.png";
import Score2 from "../image/Score2.png";
import Multipl1 from "../image/Multipl1.png";
import Multipl2 from "../image/Multipl2.png";
import Multipl3 from "../image/Multiple3.png";
import "../App.css";
import "./problem.css";
import "nprogress/nprogress.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './problem.less'

import { mockUpload } from "../common/fnUploadRequest"

const { TextArea } = Input;
const scoreList = new Map();

function ListView(props) {
  // const [span, setSpan] = useState('');
  const data = props.info;
  const allState = props.allState;
  const list = data.healthSelectRangeVOList;
  const index = props.index;
  const listItems = list.map((item, idx) => (
    <>
      <li
        className="flex-start-center"
        key={idx}
        onClick={() => props.onChangeList(index, idx)}
      >
        {data.type === 1 && (
          <div style={{ display: "inline-block" }} className="imgDiv-WH">
            <img
              src={
                allState.formPage == "b" && allState.view == "justView"
                  ? Single1
                  : item.isCheck
                    ? Single3
                    : allState.status === "1"
                      ? Single1
                      : Single2
              }
              style={{ display: "inline-block" }}
              className="img-WH"
              alt="img"
            />
          </div>
        )}
        {[2, 8].includes(data.type) && (
          <div style={{ display: "inline-block" }} className="imgDiv-WH">
            <img
              src={
                allState.formPage == "b" && allState.view == "justView"
                  ? Multipl1
                  : item.isCheck
                    ? Multipl3
                    : allState.status === "1"
                      ? Multipl1
                      : Multipl2
              }
              style={{ display: "inline-block" }}
              className="img-WH"
              alt="img"
            />
          </div>
        )}

        <div className="li-list flex-start-center">
          <span
            style={{
              verticalAlign: "middle",
              textAlign: "left",
              color: !item.isCheck ? "#333333" : "#115FE1",
              fontWeight: item.isCheck ? "600" : "400",
            }}
            className="li-span"
          >
            {item.name}
          </span>
        </div>
      </li>
      {item.extendData && (
        <ImgView url={item.extendData} />
      )}

      {item.type == 1 && item.isCheck &&
        <TextArea
          onResize={false}
          className={styles.textArea}
          value={item.extendValue}
          onChange={e => props.onChangeList(index, idx, e.target.value)}
          disabled={allState.status === "1"}
        />
      }
    </>
  ));

  return (
    <div className="paddingT24 nav_ul">
      <ul style={{ margin: 0, padding: 0 }}>{listItems}</ul>
    </div>
  );
}

const ImgView = (props) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div style={{ textAlign: 'left', marginLeft: '8vw', marginBottom: "6vw", }}>
        <img
          style={{ width: '28vw', height: '28vw' }}
          src={props.url}
          onClick={() => {
            setVisible(true)
          }}
        />
      </div>
      <ImageViewer
        image={props.url}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      />
    </>
  )
}
function Richtext(props) {
  let str = ""
  if (props.info.healthSelectRangeVOList.length > 0 && props.info.healthSelectRangeVOList[0].obj != null && props.info.healthSelectRangeVOList[0].obj != "undefined") {
    str = props.info.healthSelectRangeVOList[0].obj.value
  }
  return (props.allState.status == 1 || props.allState.view == "view" || props.allState.view == "justView") ? (
    <div className="textArea color-333">
      {str}
    </div>
  ) : (
    <div>
      <TextArea
        placeholder="请输入"
        bordered={false}
        className="textArea color-333"
        style={{
          borderStyle: "none",
          resize: "none",
          borderColor: "white",
          outline: "none",
        }}
        disabled={props.allState.status == 1 || props.allState.view == "view" || props.allState.view == "justView"}
        value={str}
        onChange={(e) => props.onChangeTextArea(props.index, e)}
      />
    </div>
  );
}

function NextTwo(props) {
  return (
    <div>
      <div className="flex-start-center bottomTap">
        <div className="next-two-text-one" onClick={() => props.onSlickPrev()}>
          上一题
        </div>
        <div
          className="next margin56"
          style={{
            backgroundColor: props.allState.isCheckList[
              props.allState.indexNext
            ].isCheckSome
              ? "#115FE1"
              : "rgba(17,95,225,0.4)",
          }}
        >
          <div className="nexttext" onClick={() => props.onSlickNext()}>
            {props.allState.nextTitle}
          </div>
        </div>
      </div>
    </div>
  );
}

function Next2(props) {
  if (props.allState.pageList.length > 0) {
    let canClick = false;
    if (props.allState.inputTypeId == 4) {
      canClick =
        (props.allState.healthSolutionInputDataVOList.length ==
          props.allState.pageList[0].healthInputResultVOS.length - 1 &&
          props.allState.nextTitle == "下一页") ||
        props.allState.healthSolutionInputDataVOList.length >=
        props.allState.pageList[0].healthInputResultVOS.length +
        props.allState.pageList[1].healthInputResultVOS.length -
        1;
    } else {
      canClick = props.allState.healthSolutionInputDataVOList.length >= 1
    }
    return (
      <div>
        <div className="flex-start-center bottomButton">
          <div
            className="next2"
            style={{
              backgroundColor: canClick ? "#115FE1" : "rgba(17,95,225,0.4)",
            }}
          >
            <div className="nexttext" onClick={() => props.onSlickNext()}>
              {props.allState.nextTitle}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function Score(props) {
  console.log(props);
  const allState = props.allState;
  const array = props.info.healthSelectRangeVOList;
  const questionIndex = props.index;
  return (
    <div className="">
      <div className="flex-between-center paddingT24">
        <div className="score-title">不满意</div>
        <div className="score-title">满意</div>
      </div>
      <div className="score-img flex-around-center">
        {array.map((it, index) => (
          <div
            style={{ display: "inline-block" }}
            className="imgDiv-WH"
            key={index}
          >
            <img
              src={
                allState.formPage == "b" && allState.view == "justView"
                  ? Score1
                  : it.isCheck
                    ? Score2
                    : Score1
              }
              alt="single3"
              style={{ display: "inline-block" }}
              className="img-WH"
              onClick={() => props.onChangeScore(questionIndex, index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function OnDone(props) {
  return (
    <div>
      <div className="flex-center-center doneHeader">
        <div className="done">您已提交过该问卷</div>
      </div>
    </div>
  );
}

function Choose(props) {
  const data = props.data;
  const allState = props.allState;
  const index = props.index;
  if (allState.name == "服务满意度评价") {
    return (
      <Score
        allState={allState}
        index={index}
        onChangeScore={props.onChangeScore}
        info={data}
      />
    );
  } else {
    if (data.type == 1 || data.type == 2 || data.type == 8) {
      return (
        <ListView
          info={data}
          index={index}
          onChangeList={props.onChangeList}
          allState={allState}
        />
      );
    } else if (data.type === 0) {
      return (
        <Richtext
          info={data}
          index={index}
          allState={allState}
          onChangeTextArea={(index, msg) => props.onChangeTextArea(index, msg)}
        />
      );
    } else if (data.type === 3) {
      return ImageUpload(props);
    } else {
      return null;
    }
  }
}

function ImageUpload(props) {
  const maxCount = props.data.healthSelectRangeVOList.length
  const [fileList, setFileList] = useState([])
  let urls = []
  if (props.allState.status == 1) {
    fileList.splice(0, fileList.length)
    if (props.data.healthSelectRangeVOList[0].obj && props.data.healthSelectRangeVOList[0].obj.value) {
      urls = props.data.healthSelectRangeVOList[0].obj.value.split(",")
    }
    urls.forEach(it => {
      fileList.push({ url: it })
    })
  }
  let urlStr
  for (let index = 0; index < fileList.length; index++) {
    const element = fileList[index];
    if (index == 0) {
      urlStr = element.url
    } else {
      urlStr = urlStr + "," + element.url
    }
  }
  props.data.healthSelectRangeVOList[0].value = urlStr
  props.data.healthSelectRangeVOList[0].obj.value = urlStr
  return (
    <ImageUploader
      value={fileList}
      onChange={setFileList}
      // multiple={true}
      maxCount={maxCount}
      upload={mockUpload}
      deletable={props.allState.status == 0}
      showUpload={fileList.length < maxCount && props.allState.status == 0 && (props.allState.view != "view" || props.allState.view != "justView")}
      onCountExceed={exceed => {
        Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`)
      }}
    >
    </ImageUploader>
  )
}


function InputItem(props) {
  let subject = props.subject;
  if (props.subject.type == 0 && subject.name.indexOf("日期") != -1) {
    let date;
    if (subject.healthSelectRangeVOList[0].obj.value != null) {
      let dateStr = subject.healthSelectRangeVOList[0].obj.value.replace(/\-/g, "/");
      date = new Date(dateStr);

    }
    function selectDate(e) {
      console.log("出生日期", props.subject);
      let isHas = false;
      var year = e.getFullYear(); //获取完整的年份(4位,1970-????)
      var month = e.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      var day = e.getDate(); //获取当前日(1-31)
      props.subject.healthSelectRangeVOList[0].obj.value =
        year + "-" + month + "-" + day;
      props.allState.healthSolutionInputDataVOList.forEach((element) => {
        if (
          element.inputId == props.subject.healthSelectRangeVOList[0].inputId
        ) {
          element = props.subject.healthSelectRangeVOList[0].obj;
          isHas = true;
        }
      });
      if (!isHas) {
        console.log(props.subject.name);
        props.allState.healthSolutionInputDataVOList.push(
          props.subject.healthSelectRangeVOList[0].obj
        );
      }
    }
    return (
      <DatePicker
        mode="date"
        title={subject.name}
        extra="请选择"
        value={date}
        disabled={
          props.allState.view == "view" ||
          props.allState.view == "justView" ||
          (props.allState.status == 1 && props.allState.formPage == "c")
        }
        onChange={(date) => selectDate(date)}
      >
        <List.Item arrow="horizontal">
          <div style={{ fontSize: 17 }}>{subject.name}</div>
        </List.Item>
      </DatePicker>
    );
  } else if (props.subject.type == 0) {
    function inputText(e) {
      let isHas = false;
      props.subject.healthSelectRangeVOList[0].obj.value = e.target.value;
      props.allState.healthSolutionInputDataVOList.forEach((element) => {
        if (
          element.inputId == props.subject.healthSelectRangeVOList[0].inputId
        ) {
          element = props.subject.healthSelectRangeVOList[0].obj;
          isHas = true;
        }
      });
      if (!isHas) {
        props.allState.healthSolutionInputDataVOList.push(
          props.subject.healthSelectRangeVOList[0].obj
        );
      }
    }
    function changeTwoDecimal(x) {
      let list = x.split(".");
      console.log(list, list[1])
      if (list.length > 1 && list[1].length > 2) {
        list[1] = list[1].substring(0, 2)
        console.log("list[1]", list[1])
      }
      if (list[0] * 1 > 400) {
        return "400"
      }
      if (list.length > 1) {
        return list[0] + "." + list[1]
      } else {
        return list[0]
      }
    }
    function oninput(e) {
      if (
        subject.name.indexOf("身高") != -1 ||
        subject.name.indexOf("体重") != -1 ||
        subject.name.indexOf("电话") != -1
      ) {
        e.target.value = e.target.value
          .replace(/[^0-9.]/g, "")
          .replace(/[.][0-9]*[.]/, ".");
      }

      if (
        subject.name.indexOf("身高") != -1 ||
        subject.name.indexOf("体重") != -1
      ) {
        e.target.value = changeTwoDecimal(e.target.value);
      }
      if (subject.name.indexOf("电话") != -1 && e.target.value.length > 11) {
        e.target.value = e.target.value.substring(0, 11)
      }
    }
    console.log("status", props.allState.status);
    var str =
      subject.name.indexOf("身高") != -1
        ? "CM"
        : subject.name.indexOf("体重") != -1
          ? "KG"
          : "";
    return (
      <div className="item-view">
        <div style={{ fontSize: 17 }}>{subject.name}</div>
        <div className="item-view-item">
          <Input
            placeholder={"请输入" + subject.name}
            key={subject.name}
            bordered={false}
            className="itemTextArea color-333"
            style={{
              borderStyle: "none",
              resize: "none",
              borderColor: "white",
              outline: "none",
            }}
            disabled={
              props.allState.view == "view" ||
              props.allState.view == "justView" ||
              (props.allState.status == 1 && props.allState.formPage == "c")
            }
            value={
              props.allState.view == "view" ||
                props.allState.status == "1" ||
                props.allState.view == "justView"
                ? props.subject.healthSelectRangeVOList[0].obj.value
                : props.subject.healthSelectRangeVOList[0].value
            }
            onChange={(e) => inputText(e)}
            onInput={(e) => oninput(e)}
          />
          <div> {str}</div>
        </div>
      </div>
    );
  } else if (props.subject.type == 1) {
    function selectOnClick(e, idx) {
      if (props.allState.status == 0) {
        props.subject.healthSelectRangeVOList.forEach((e) => {
          e.isCheck = false;
        });
        let isHas = false;
        props.subject.healthSelectRangeVOList[idx].isCheck = true;
        props.subject.healthSelectRangeVOList[idx].obj.isCheck = true;
        props.allState.healthSolutionInputDataVOList.forEach((element) => {
          if (
            element.inputId ==
            props.subject.healthSelectRangeVOList[idx].inputId
          ) {
            element = props.subject.healthSelectRangeVOList[idx].obj;
            isHas = true;
          }
        });
        if (!isHas) {
          props.allState.healthSolutionInputDataVOList.push(
            props.subject.healthSelectRangeVOList[idx].obj
          );
        }
      }
    }
    const listItems = props.subject.healthSelectRangeVOList.map((item, idx) => (
      <div
        key={idx}
        className={item.isCheck ? "selected-item" : "select-item"}
        onClick={(e) => selectOnClick(e, idx)}
      >
        <span
          className={item.isCheck ? "selected-text" : ""}
          style={{ fontSize: 14 }}
        >
          {item.name}
        </span>
      </div>
    ));
    return (
      <div className="item-view">
        <div style={{ fontSize: 17 }}>{subject.name}</div>
        <div className="select-view">{listItems}</div>
      </div>
    );
  } else if (props.subject.type == 2) {
  } else if (props.subject.type == 4) {
  } else {
    return (
      <div className="item-view">
        <div>{subject.name}</div>
      </div>
    );
  }
}

function PageChoose(props) {
  console.log("props", props);
  const listItems = props.data.healthInputResultVOS.map((item, idx) => (
    <div key={idx}>
      <InputItem subject={item} allState={props.allState} />
      <div className="divider"></div>
    </div>
  ));

  return (
    <div className="page-view">
      <div> {listItems}</div>
    </div>
  );
}
const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

class Problem extends React.Component {
  constructor(props) {
    super();
    this.onGoNext = this.onGoNext.bind(this);

    this.onAfterChange = this.onAfterChange.bind(this);
    this.onBeforeChange = this.onBeforeChange.bind(this);
    this.onChangeList = this.onChangeList.bind(this);
    this.onChangeTextArea = this.onChangeTextArea.bind(this);
    this.onChangeScore = this.onChangeScore.bind(this);
    this.onSlickNext = this.onSlickNext.bind(this);
    this.onSlickPrev = this.onSlickPrev.bind(this);
    this.onChangeSlickNext = this.onChangeSlickNext.bind(this);
    this.onChangeSlickNextToEnd = this.onChangeSlickNextToEnd.bind(this);
    this.onChangeSlickNextToEnd2 = this.onChangeSlickNextToEnd2.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.onGoPhysicalExaminationResult = this.onGoPhysicalExaminationResult.bind(this);
    this.state = {
      indexNext: 0,
      NProgressNumber: 0.0,
      touchMove: false,
      nextTitle: "下一题",
      title: "下一题",
      RichtextValeu: "",
      voList: [],
      pageList: [],
      totalScore: 0,
      isNetDone: false,
      herfValue: "",
      date: now,
      isCheckList: [
        {
          isCheckSome: false,
        },
      ],
      healthSolutionInputDataVOList: [],
      enterTime: 0,
    };
  }

  onChangeList(index, data, extendValue = false) {
    if (
      (this.state.formPage == "b" && this.state.view == "justView") ||
      (this.state.formPage == "b" && this.state.view == "view")
    ) {
      return;
    }
    let indexNext = index;
    let listIndex = data;
    if (this.state.status === "1") {
      return;
    } else {
      let isCheckList = this.state.isCheckList;
      this.state.voList[indexNext].healthSelectRangeVOList.forEach(
        (it, idx) => {
          if (this.state.voList[indexNext].type === 1) {
            //单选框
            if (idx == listIndex) {
              it.isCheck = !it.isCheck;
            } else {
              it.isCheck = false;
            }
          } else if (this.state.voList[indexNext].type === 2 || this.state.voList[indexNext].type === 8) {
            //多选和复合文本域
            if ((extendValue || extendValue === '') && idx === listIndex) {
              it.extendValue = extendValue
            } else if (idx === listIndex) {
              it.isCheck = !it.isCheck;
            }
          }
        }
      );

      isCheckList[indexNext].isCheckSome = this.state.voList[
        indexNext
      ].healthSelectRangeVOList.some((e, i) => {
        return e.isCheck == true;
      });

      this.setState({ isCheckList: isCheckList }, () =>
        console.log(this.state)
      );
    }

    this.setState(
      {
        voList: this.state.voList,
      },
      () => console.log(this.state)
    );
  }

  onChangeScore(questionIndex, i) {
    if (
      (this.state.formPage == "b" && this.state.view == "justView") ||
      (this.state.formPage == "b" && this.state.view == "view")
    ) {
      return;
    }

    console.log(i);
    let that = this;
    let indexNext = questionIndex;
    if (this.state.status === "1") {
    } else {
      let healthSelectRangeVOList =
        that.state.voList[indexNext].healthSelectRangeVOList;
      console.log(healthSelectRangeVOList);
      if (i == 0) {
        healthSelectRangeVOList[0].isCheck =
          !healthSelectRangeVOList[0].isCheck;
      } else {
        for (let n = i; n >= 0; n--) {
          console.log(n);
          healthSelectRangeVOList[n].isCheck = true;
          if (i == 0 && healthSelectRangeVOList[i + 1].isCheck === true) {
            console.log(i, "i", !healthSelectRangeVOList[0].isCheck);
            healthSelectRangeVOList[0].isCheck = false;
            console.log(i, "i", healthSelectRangeVOList[0].isCheck);
          }
        }
      }

      if (i + 1 !== healthSelectRangeVOList.length) {
        console.log(i + 1 !== healthSelectRangeVOList.length);
        if (healthSelectRangeVOList[i + 1].isCheck) {
          for (let a = 4; a >= i + 1; a--) {
            console.log(a, "a");
            healthSelectRangeVOList[a].isCheck = false;
          }
        }
      }

      this.setState({ voList: that.state.voList }, () =>
        console.log(this.state)
      );

      if (healthSelectRangeVOList[0].isCheck) {
        let isCheckList = this.state.isCheckList;
        isCheckList[indexNext].isCheckSome = true;
        this.setState(
          {
            isCheckList: that.state.isCheckList,
            touchMove: true,
          },
          () => console.log(this.state)
        );
      }
    }
  }

  onChangeTextArea(index, e) {
    e.persist();
    const that = this;
    console.log(index)
    if (that.state.status === "1") {
    } else {
      console.log(e.target.value);
      if (e.target.value.length > 1) {
        let isCheckList = that.state.isCheckList;
        isCheckList[index].isCheckSome = true;
        that.setState(
          {
            isCheckList: isCheckList,
            touchMove: true,
          },
          () => console.log(that.state)
        );
      }
      that.state.voList[index].healthSelectRangeVOList[0].value = e.target.value;
      that.setState(
        {
          voList: that.state.voList,
        },
        () => console.log(that.state)
      );
    }
  }

  onItemTextInput(result, e) { }

  onSlickNext() {
    this.slider.slickNext();
  }
  onSlickPrev() {
    this.slider.slickPrev();
  }

  componentDidMount() {
    //请求数据

    console.log("window.location.href", window.location.href);
    let u = navigator.userAgent;
    console.log(u);
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    console.log(isIOS);
    // if (isIOS) {
    //   inobounce.enable()
    // }

    // let baseURL = "http://192.168.88.117:9000/" //庞
    // let baseURL = "https://yzh.ipharmacare.net/csp/";
    // let baseURL = onBaseURL();
    // console.log(baseURL);
    // this.setState({ baseURL: baseURL });
    this.setState({ herfValue: window.location.href.split("?")[1] });
    console.log(window.location.href.split("?"));
    let herf = window.location.href.split("?")[1].split("&");
    herf.forEach((it, item) => {
      let name = it.split("=");
      this.setState({ [name[0].toString()]: name[1] }, () => {
        const that = this;
        if (that.state.environment == "PROD") {
          that.setState({
            baseURL: "https://server.etube365.com/csp/",
          });
        } else if (that.state.environment == "UAT") {
          that.setState({
            baseURL: "https://server.etube365.com/tcsp/",
          });
        } else if (that.state.environment == "PRE") {
          that.setState({
            baseURL: "https://server.etube365.com/pcsp/",
          });
        }
        if (this.state.formPage == "b") {
          if (this.state.environment == "PROD") {
            this.setState({
              baseURL: "https://server.etube365.com/bsp/",
            });
          } else if (this.state.environment == "UAT") {
            this.setState({
              baseURL: "https://server.etube365.com/tbsp/",
            });
          } else if (this.state.environment == "PRE") {
            this.setState({
              baseURL: "https://server.etube365.com/pbsp/",
            });
          }
        }
        if (this.state.formPage == "a") {
          if (this.state.environment == "PROD") {
            this.setState({
              baseURL: "https://server.etube365.com/asp/",
            });
          } else if (this.state.environment == "UAT") {
            this.setState({
              baseURL: "https://server.etube365.com/admin-test/",
            });
          } else if (this.state.environment == "PRE") {
            this.setState({
              baseURL: "https://server.etube365.com/admin-pre/",
            });
          }
        }
        if (this.state.environment == "undefined") {
          that.setState({
            baseURL: "https://server.etube365.com/csp/",
          });
        }

      });
    });
    this.setState({
      baseURL: "http://192.168.88.117:9000/",
    });

    setTimeout(() => {
      this.getHealthInputGroupRelationByCondition();
    }, 500);
  }

  readMessage(procFlag) {
    const that = this;
    let data = {
      id: that.state.itemId, // 消息ID
      procFlag: procFlag, // 处理状态[0未处理] 1：已处理 2：已拒绝
      readFlag: "1", // 0未读 1已读
    };

    return axios({
      method: "POST",
      url: that.state.baseURL + "proxy/message/store/client/updateMessageStore",
      data: data,
      headers: { token: that.state.token },
    })
      .then(function (res) {
        console.log(res, "readMessage");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateFollowedPlanTaskStatus(taskId) {
    const that = this;
    return axios({
      method: "GET",
      url:
        that.state.baseURL +
        "followed/task/client/updateFollowedPlanTaskStatus/" +
        taskId,
      headers: { token: that.state.token },
    })
      .then(function (res) {
        console.log(res, "updateFollowedPlanTaskStatus");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  addHealthSolutionGroupDataAndInputData() {
    const that = this;
    for (var i = 0; i < that.state.healthSolutionInputDataVOList.length; i++) {
      for (
        var j = i + 1;
        j < that.state.healthSolutionInputDataVOList.length;
        j++
      ) {
        if (
          that.state.healthSolutionInputDataVOList[i].inputId ==
          that.state.healthSolutionInputDataVOList[j].inputId
          && that.state.healthSolutionInputDataVOList[i].type != 2) {
          that.state.healthSolutionInputDataVOList.splice(i, 1);
          j--;
        }
      }
    }
    let data = {
      groupId: that.state.groupId,
      solutionId: that.state.solutionId,
      uploadTime: that.init(),
      patientId: that.state.patientId,
      healthSolutionInputDataVOList: that.state.healthSolutionInputDataVOList,
    };
    console.log(that.state);
    if (that.state.hospitalGroupId !== "undefined") {
      data.hospitalGroupId = that.state.hospitalGroupId;
    }
    if (that.state.sessionCode !== "undefined") {
      data.sessionCode = that.state.sessionCode;
    }
    if (that.state.sessionType !== "undefined") {
      data.sessionType = that.state.sessionType;
    }
    if (that.state.business !== "undefined") {
      data.business = that.state.business;
    }
    if (that.state.traceCode !== "undefined") {
      data.traceCode = that.state.traceCode;
    }
    if (that.state.replyId !== "undefined") {
      data.replyId = that.state.replyId;
    }
    if (that.state.serveCode !== "undefined") {
      data.serveCode = that.state.serveCode;
    }
    return axios({
      method: "POST",
      url:
        that.state.baseURL +
        "solution/healthSolutionGroupData/client/addHealthSolutionGroupDataAndInputData",
      data: data,
      headers: { token: this.state.token },
    })
      .then(function (res) {
        console.log(res, "addHealthSolutionGroupDataAndInputData");

        let herfValue = that.state.herfValue;
        herfValue = herfValue.replace(
          "idOne=" + that.state.id,
          "idOne=" + res.data.data.id
        );
        herfValue = herfValue.replace("status=0", "status=1");
        that.setState({ herfValue: herfValue });
        console.log(
          "idOne=" + that.state.id,
          "idOne=" + res.data.data.id,
          herfValue
        );
        if (res.data.status === 0) {
          if (that.state.itemId !== "undefined") {
            that.readMessage(1);
          }
          Toast.success("提交成功");
          if (that.state.taskId !== "undefined") {
            that.updateFollowedPlanTaskStatus(that.state.taskId);
          }

          // if(res.data.data.score) {groupDataId
          that.setState({
            idOne: res.data.data.id,
            status: 1,
            view: "view",
            totalScore: res.data.data.score,
            warnResult: res.data.data.warnResult,
            color: res.data.data.color,
            groupDataId: res.data.data.id,
          });
          // };
          return res.data.status;
        } else {
          debugger
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  init() {
    let nowTime = new Date();
    let nowYear = nowTime.getFullYear();
    let nowMonth = nowTime.getMonth() + 1;
    let nowDay = nowTime.getDate();
    let nowHh = nowTime.getHours();
    let nowMm = nowTime.getMinutes();
    const monthStr = nowMonth >= 10 ? nowMonth : "0" + nowMonth;
    const dayStr = nowDay >= 10 ? nowDay : "0" + nowDay;
    const hhStr = nowHh >= 10 ? nowHh : "0" + nowHh;
    const mmStr = nowMm >= 10 ? nowMm : "0" + nowMm;

    let timeStr =
      nowYear +
      "-" +
      monthStr +
      "-" +
      dayStr +
      " " +
      hhStr +
      ":" +
      mmStr +
      ":" +
      "00";
    console.log(timeStr);
    return timeStr;
  }

  getHealthInputGroupRelationByCondition() {
    const that = this;
    let data = {
      id: that.state.id,
      inputTypeId: that.state.inputTypeId,
    };
    if (that.state.inputTypeId == 4 || that.state.inputTypeId == 6 || that.state.inputTypeId == 5) {
      if (that.state.inputTypeId == 4) {
        this.setState({
          nextTitle: "下一页",
        });
      } else {
        this.setState({
          nextTitle: "提交",
        });
      }

      return axios({
        method: "POST",
        url:
          that.state.baseURL +
          "solution/healthInputGroup/client/getQuestionnaireInputGroupByCondition",
        data: data,
        headers: { token: that.state.token },
      })
        .then(function (res) {
          that.state.pageList = res.data.data;
          let groupId;
          that.state.pageList.forEach((item) => {
            let subjectList = item.healthInputResultVOS;
            subjectList.forEach((subjectItem) => {
              subjectItem.healthSelectRangeVOList.forEach((item, idx) => {
                groupId = subjectItem.groupId;
                item.obj = {
                  inputId: item.inputId,
                  solutionId: that.state.solutionId,
                  groupId: subjectItem.groupId,
                  score: item.score,
                  name: item.name,
                  healthSelectRangeId: item.id,
                  type: item.type
                };
                item.isCheck = false;
              });
            });
          });
          that.setState({
            pageList: that.state.pageList,
            groupId: groupId,
          });
          if (that.state.status == "1") {
            that.getTableDataDetailById();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      return axios({
        method: "POST",
        url:
          that.state.baseURL +
          "solution/healthInputGroup/client/getHealthInputGroupRelationByCondition",
        data: data,
        headers: { token: that.state.token },
      })
        .then(function (res) {
          that.setState({
            isNetDone: true,
          });
          console.log(res);
          if (res.data.status === 0) {
            let list = res.data.data[0].healthInputResultVOList;
            list.forEach((it, index) => {
              it.healthSelectRangeVOList.forEach((item, idx) => {
                item.obj = {
                  inputId: item.inputId,
                  solutionId: that.state.solutionId,
                  groupId: res.data.data[0].id,
                  score: item.score,
                  healthSelectRangeId: item.id,
                  type: it.type
                };
                item.isCheck = false;
              });

              that.state.isCheckList.push({
                isCheckSome: false,
              });
            });

            let number = Number(parseFloat(1 / list.length).toFixed(3));
            if (list.length === 1) {
              that.setState({ title: "提交问卷" }, () =>
                console.log(that.state)
              );
              if (that.state.conversation == "conversation") {
                that.setState({ title: "完成" }, () => console.log(that.state));
              }
            } else {
              // NProgress.configure({
              //   showSpinner: false,
              //   minimum: 0.1,
              //   trickle: false,
              // });
              // NProgress.start();
            }
            console.log(list);
            that.setState(
              {
                groupId: res.data.data[0].id,
                voList: list,
                NProgressNumber: number,
                add: number,
                length: list.length,
                name: res.data.data[0].name,
              },
              () => console.log(that.state)
            );

            if (that.state.formPage == "b" && that.state.view == "justView") {
              that.setState({
                touchMove: true,
              });
            }

            if (
              that.state.status == "1" &&
              res.data.data[0].name == "体检健康问卷"
            ) {
              that.setState({
                groupDataId: that.state.idOne,
              });
              that.onGoPhysicalExaminationResult();
              return false;
            }

            if (that.state.status == "1") {

              that.getTableDataDetailById();

            }
            return res.data.status;
          } else {
            Toast.info("获取问卷失败");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  getTableDataDetailById() {
    const that = this;
    return axios({
      method: "GET",
      url:
        that.state.baseURL +
        "solution/healthSolutionGroupData/client/getTableDataDetailById?id=" +
        that.state.idOne,
      headers: { token: that.state.token },
    })
      .then(function (res) {
        that.setState({
          isNetDone: true,
        });
        if (res.data.status === 0) {
          let healthSolutionInputDataVOList =
            res.data.data.healthSolutionInputDataVOList;
          if (that.state.inputTypeId == 4 || that.state.inputTypeId == 6 || that.state.inputTypeId == 5) {
            healthSolutionInputDataVOList.forEach((it, index) => {
              that.state.pageList.forEach((item) => {
                let subjectList = item.healthInputResultVOS;
                subjectList.forEach((subjectItem) => {
                  subjectItem.healthSelectRangeVOList.forEach((item, idx) => {
                    if (
                      (subjectItem.type == 1 || subjectItem.type == 2) &&
                      item.name == it.value
                    ) {
                      item.obj = it;
                      item.isCheck = true;
                    } else if (
                      item.inputId == it.inputId &&
                      subjectItem.type != 1 &&
                      subjectItem.type != 2
                    ) {
                      item.obj = it;
                      item.isCheck = true;
                    }
                  });
                });
              });
            });
            if (that.state.inputTypeId == 6) {
              that.setState({
                solutionId: res.data.data.solutionId
              })
              that.onGoGrowthCurve();
            }
          } else {
            healthSolutionInputDataVOList.forEach((it, index) => {
              if (that.state.name == "服务满意度评价") {
                that.state.voList.forEach((item, idx) => {
                  item.healthSelectRangeVOList.forEach((e, i) => {
                    if (e.inputId == it.inputId) {
                      if (i < it.score) {
                        e.isCheck = true;
                      }
                    }
                  });
                });
              } else {
                that.state.voList.forEach((item, idx) => {
                  item.healthSelectRangeVOList.forEach((e, i) => {
                    if (e.inputId == it.inputId && e.name == it.value) {
                      e.isCheck = true;
                      if (it.extendValue) {
                        e.extendValue = it.extendValue
                      }
                    } else if (e.inputId == it.inputId && (item.type === 0 || item.type === 3)) {
                      e.obj = it;
                      e.isCheck = true;
                    }
                  });
                });
              }
            });
          }
          console.log(that.state.voList)
          that.setState(
            {
              touchMove: true,
              voList: that.state.voList,
              totalScore: res.data.data.score,
              warnResult: res.data.data.warnResult,
              color: res.data.data.color,
            },
            () => console.log(that.state)
          );

          return res.data.status;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) { }

  onGoNext(props) {
    console.log(props);
    let title;
    if (this.state.status === "1") {
      title = "已提交";
    } else {
      title = "提交完成";
    }

    let info = {
      title: title,
      name: this.state.name,
      totalScore: this.state.totalScore,
      warnResult: this.state.warnResult,
      color: this.state.color,
      isCheckList: this.state.isCheckList,
      indexNext: this.state.indexNext,
      back: this.state.back,
      conversation: this.state.conversation,
      status: this.state.status,
      formPage: this.state.formPage,
      state: this.state,
      herfValue: this.state.herfValue,
    };
    console.log("info", info);
    this.props.history.replace({
      pathname: "/submit",
      state: info,
    });
  }

  onGoPhysicalExaminationResult(props) {
    console.log(props);
    let info = {
      name: this.state.name,
      isCheckList: this.state.isCheckList,
      indexNext: this.state.indexNext,
      back: this.state.back,
      conversation: this.state.conversation,
      status: this.state.status,
      token: this.state.token,
      formPage: this.state.formPage,
      groupDataId: this.state.groupDataId,
      baseURL: this.state.baseURL,
    };
    console.log(info);
    this.props.history.push({
      pathname: "/physicalExaminationResult",
      state: info,
    });
  }

  onGoGrowthCurve(props) {
    console.log(props);
    let info = {
      name: this.state.name,
      isCheckList: this.state.isCheckList,
      indexNext: this.state.indexNext,
      back: this.state.back,
      conversation: this.state.conversation,
      status: this.state.status,
      token: this.state.token,
      formPage: this.state.formPage,
      groupDataId: this.state.groupDataId,
      baseURL: this.state.baseURL,
      solutionId: this.state.solutionId,
      patientId: this.state.patientId,
    };
    console.log("")
    this.props.history.replace({
      pathname: "/growthCurveResultPage",
      state: info,
    });
  }

  componentWillUnmount() {
    console.log("componentWillUnmount hospital-account-management");
    this.setState = (state, callback) => {
      return;
    };
  }

  onBeforeChange(o, n) {
    console.log(o, n, "onBeforeChange");
  }

  onAfterChange(index) {
    const that = this;
    if (that.state.length === 1) {
      that.onGoNext();
    } else {
      if (index > that.state.indexNext) {
        let number = that.state.NProgressNumber;
        number = number + that.state.add;
        if (index !== that.state.voList.length - 1) {
          // NProgress.set(number);
        }
        that.setState({ NProgressNumber: number }, () =>
          console.log(that.state.NProgressNumber)
        );
      } else if (index < that.state.indexNext) {
        console.log("<");
        let number = that.state.NProgressNumber;
        number = number - that.state.add;
        // NProgress.set(number);
        that.setState({ NProgressNumber: number }, () =>
          console.log(that.state.NProgressNumber)
        );
      }

      if (index === that.state.voList.length - 1) {
        // NProgress.set(0.99999999);
      }

      if (that.state.status === "1") {
        console.log(that.state);
        if (index == that.state.indexNext && index == that.state.length - 1) {
          console.log("111111111111111111111111111111");
          NProgress.done();
          if (that.state.name === "体检健康问卷") {
            that.setState(
              {
                groupDataId: that.state.idOne,
              },
              () => this.onGoPhysicalExaminationResult()
            );
          } else {
            that.onGoNext();
          }
        }
      }
    }
    if (that.state.formPage == "b" && that.state.view == "justView") {
      that.setState({
        touchMove: true,
      });
    }

    that.setState({ indexNext: index }, () => console.log("2"));
  }

  onChangeSlickNext() {
    const that = this;
    if (that.state.status === "1") {
    } else if (that.state.status === "0") {
      let indexNext = that.state.indexNext;
      let total = 0;
      if (that.state.name !== "服务满意度评价") {
        that.state.voList[indexNext].healthSelectRangeVOList.forEach(
          (it, idx) => {
            let healthSolutionInputDataVOList =
              that.state.healthSolutionInputDataVOList;
            if (it.isCheck) {
              healthSolutionInputDataVOList.push(it.obj);
            }
            that.setState(
              {
                healthSolutionInputDataVOList: healthSolutionInputDataVOList,
              },
              () => console.log(that.state)
            );
          }
        );
      } else {
        let number = 0;
        that.state.voList[indexNext].healthSelectRangeVOList.forEach(
          (it, idx) => {
            if (it.isCheck) {
              ++number;
            }
          }
        );

        let healthSolutionInputDataVOList =
          that.state.healthSolutionInputDataVOList;
        healthSolutionInputDataVOList.push(
          that.state.voList[indexNext].healthSelectRangeVOList[number - 1].obj
        );
        that.setState(
          {
            healthSolutionInputDataVOList: healthSolutionInputDataVOList,
          },
          () => console.log(that.state)
        );
      }

      that.setState(
        {
          voList: that.state.voList,
        },
        () => console.log(that.state)
      );

      scoreList.set(indexNext, total);
      for (let [key, value] of scoreList) {
        console.log(`${key} = ${value}`);
      }

      if (that.state.isCheckList[that.state.indexNext].isCheckSome) {
        this.onSlickNext();
      } else {
        that.setState(
          {
            touchMove: that.state.isCheckList[that.state.indexNext].isCheckSome,
          },
          () => console.log(that.state)
        );
      }
    }
  }

  onChangeSlickNextToEnd() {
    const that = this;
    console.log("onChangeSlickNextToEnd");
    that.onChangeSlickNext();
    if (that.state.length === 1 && that.state.isCheckList[0].isCheckSome) {
      let gapTime = 1000; //间隔时间，如果interval不传，则默认500ms
      let backTime = new Date(); //第一次函数return即触发的时间
      console.log(that.state.enterTime);
      if (backTime - that.state.enterTime > gapTime) {
        console.log("throttle");
        that.setState(
          {
            enterTime: backTime,
          },
          async () => {
            let info = await this.addHealthSolutionGroupDataAndInputData();
            if (info === 0) {
              setTimeout(() => {
                NProgress.done();
                that.onGoNext();
              }, 10);
            }
          }
        ); //赋值给第一次触发的时间，这样就保存了第二次触发的时间

        console.log(that.state.enterTime);
      }
    } else {
      if (
        that.state.indexNext === that.state.voList.length - 1 &&
        that.state.isCheckList[that.state.indexNext].isCheckSome
      ) {
        let gapTime = 1000; //间隔时间，如果interval不传，则默认500ms
        let backTime = new Date(); //第一次函数return即触发的时间
        if (backTime - that.state.enterTime > gapTime) {
          console.log("throttle");
          that.setState(
            {
              //赋值给第一次触发的时间，这样就保存了第二次触发的时间
              enterTime: backTime,
            },
            async () => {
              let info = await this.addHealthSolutionGroupDataAndInputData();
              if (info === 0) {
                setTimeout(() => {
                  NProgress.done();
                  if (that.state.name === "体检健康问卷") {
                    this.onGoPhysicalExaminationResult();
                  } else {
                    that.onGoNext();
                  }
                }, 10);
              }
            }
          );
          console.log(that.state.enterTime);
        }
      }
    }
  }

  ChooseTab() {
    if (
      (this.state.formPage == "b" && this.state.view == "justView") ||
      (this.state.formPage == "b" && this.state.view == "view")
    ) {
    } else {
      if (this.state.status == "1") {
        return <OnDone />;
      } else {
        return (
          <div>
            <div className="flex-start-center bottomButton">
              <div
                className="submit_botton"
                style={{
                  backgroundColor: "#115FE1",
                }}
              >
                <div className="nexttext" onClick={() => this.onSubmit()}>
                  提   交
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  ChooseTab2() {
    if (this.state.status == "0" && this.state.inputTypeId == 4 || this.state.inputTypeId == 6 || this.state.inputTypeId == 5) {
      return (
        <Next2
          data={this.state}
          allState={this.state}
          onSlickNext={this.onChangeSlickNextToEnd2}
        />
      );
    }
  }
  onChangeSlickNextToEnd2() {
    let that = this;
    let canNext = true;
    let ishasremark = false;
    this.state.healthSolutionInputDataVOList.forEach((element) => {
      console.log(element);
      if (element.name == "备注") {
        ishasremark = true;
      }
      let hasValue = element.value && element.value.trim().length > 0;
      if (!hasValue && !element.isCheck && element.name != "备注") {
        Toast.fail("请填写" + element.name);
        canNext = false;
        return;
      }
    });
    let minLength = 100
    if (this.state.inputTypeId == 4) {
      minLength = ishasremark
        ? this.state.pageList[0].healthInputResultVOS.length +
        this.state.pageList[1].healthInputResultVOS.length
        : this.state.pageList[0].healthInputResultVOS.length +
        this.state.pageList[1].healthInputResultVOS.length -
        1;
    }
    if (this.state.inputTypeId == 5 || this.state.inputTypeId == 6) {
      console.log("inputTypeId==5");
      let netDone = true;
      if (netDone) {
        netDone = false;
        let data = {
          groupId: that.state.groupId,
          solutionId: that.state.solutionId,
          uploadTime: that.init(),
          patientId: that.state.patientId,
          healthSolutionInputDataVOList:
            that.state.healthSolutionInputDataVOList,
        };
        console.log(that.state);
        if (that.state.hospitalGroupId !== "undefined") {
          data.hospitalGroupId = that.state.hospitalGroupId;
        }
        if (that.state.sessionCode !== "undefined") {
          data.sessionCode = that.state.sessionCode;
        }
        if (that.state.sessionType !== "undefined") {
          data.sessionType = that.state.sessionType;
        }
        if (that.state.business !== "undefined") {
          data.business = that.state.business;
        }
        if (that.state.traceCode !== "undefined") {
          data.traceCode = that.state.traceCode;
        }
        if (that.state.replyId !== "undefined") {
          data.replyId = that.state.replyId;
        }
        if (that.state.serveCode !== "undefined") {
          data.serveCode = that.state.serveCode;
        }
        return axios({
          method: "POST",
          url:
            that.state.baseURL +
            "solution/healthSolutionGroupData/client/addHealthSolutionGroupDataAndInputData",
          data: data,
          headers: { token: this.state.token },
        })
          .then(function (res) {
            if (res.data.status === 0) {
              netDone = true;
              let herfValue = that.state.herfValue;
              herfValue = herfValue.replace(
                "idOne=" + that.state.id,
                "idOne=" + res.data.data.id
              );
              herfValue = herfValue.replace("status=0", "status=1");
              that.setState({ herfValue: herfValue });
              if (res.data.status === 0) {
                if (that.state.itemId !== "undefined") {
                  that.readMessage(1);
                }

                if (that.state.taskId !== "undefined") {
                  that.updateFollowedPlanTaskStatus(that.state.taskId);
                }

                // if(res.data.data.score) {groupDataId

                that.setState({
                  totalScore: res.data.data.score,
                  warnResult: res.data.data.warnResult,
                  color: res.data.data.color,
                  groupDataId: res.data.data.id,
                });
                // };
                if (that.state.inputTypeId == 6) {
                  that.onGoGrowthCurve();
                } else {
                  that.onGoNext();
                }
              }
              return res.data.status;
            } else {
              Toast.fail(res.data.msg);
            }
          })
          .catch(function (error) {
            console.log(error);
            netDone = true;
          });
      }
    } else if (
      canNext &&
      this.state.healthSolutionInputDataVOList.length ==
      this.state.pageList[0].healthInputResultVOS.length
    ) {
      console.log("onSlickNext");
      this.onSlickNext();
      this.setState({
        nextTitle: "提交问卷",
      });
    } else if (this.state.inputTypeId == 4 &&
      canNext &&
      this.state.healthSolutionInputDataVOList.length >= minLength
    ) {
      console.log("updata");
      let netDone = true;
      if (netDone) {
        netDone = false;
        let data = {
          groupId: that.state.groupId,
          solutionId: that.state.solutionId,
          uploadTime: that.init(),
          patientId: that.state.patientId,
          healthSolutionInputDataVOList:
            that.state.healthSolutionInputDataVOList,
        };
        console.log(that.state);
        if (that.state.hospitalGroupId !== "undefined") {
          data.hospitalGroupId = that.state.hospitalGroupId;
        }
        if (that.state.sessionCode !== "undefined") {
          data.sessionCode = that.state.sessionCode;
        }
        if (that.state.sessionType !== "undefined") {
          data.sessionType = that.state.sessionType;
        }
        if (that.state.business !== "undefined") {
          data.business = that.state.business;
        }
        if (that.state.traceCode !== "undefined") {
          data.traceCode = that.state.traceCode;
        }
        if (that.state.replyId !== "undefined") {
          data.replyId = that.state.replyId;
        }
        if (that.state.serveCode !== "undefined") {
          data.serveCode = that.state.serveCode;
        }
        return axios({
          method: "POST",
          url:
            that.state.baseURL +
            "solution/healthSolutionGroupData/client/addHealthSolutionGroupDataAndInputData",
          data: data,
          headers: { token: this.state.token },
        })
          .then(function (res) {
            netDone = true;
            let herfValue = that.state.herfValue;
            herfValue = herfValue.replace(
              "idOne=" + that.state.id,
              "idOne=" + res.data.data.id
            );
            herfValue = herfValue.replace("status=0", "status=1");
            that.setState({ herfValue: herfValue });
            if (res.data.status === 0) {
              if (that.state.itemId !== "undefined") {
                that.readMessage(1);
              }

              if (that.state.taskId !== "undefined") {
                that.updateFollowedPlanTaskStatus(that.state.taskId);
              }

              // if(res.data.data.score) {groupDataId
              that.setState({
                totalScore: res.data.data.score,
                warnResult: res.data.data.warnResult,
                color: res.data.data.color,
                groupDataId: res.data.data.id,
              });
              // };
              that.onGoNext();
              return res.data.status;
            }
          })
          .catch(function (error) {
            console.log(error);
            netDone = true;
          });
      }
    }
  }
  onSubmit() {
    let that = this;
    let healthSolutionInputDataVOList = [];
    that.state.voList.forEach(e => {
      e.healthSelectRangeVOList.forEach(rangeVO => {
        if (rangeVO.isCheck) {
          healthSolutionInputDataVOList.push({
            ...rangeVO.obj,
            extendValue: rangeVO.extendValue,
            extendType: rangeVO.type,
          })
        } else if (rangeVO.value) {
          rangeVO.obj.value = rangeVO.value
          healthSolutionInputDataVOList.push(rangeVO.obj)
        }
      }
      )
    }
    )

    if (that.state.isNetDone) {
      that.state.isNetDone = false;
      let data = {
        groupId: that.state.groupId,
        solutionId: that.state.solutionId,
        uploadTime: that.init(),
        patientId: that.state.patientId,
        healthSolutionInputDataVOList:
          healthSolutionInputDataVOList,
      };
      console.log(that.state);
      if (that.state.hospitalGroupId !== "undefined") {
        data.hospitalGroupId = that.state.hospitalGroupId;
      }
      if (that.state.sessionCode !== "undefined") {
        data.sessionCode = that.state.sessionCode;
      }
      if (that.state.sessionType !== "undefined") {
        data.sessionType = that.state.sessionType;
      }
      if (that.state.business !== "undefined") {
        data.business = that.state.business;
      }
      if (that.state.traceCode !== "undefined") {
        data.traceCode = that.state.traceCode;
      }
      if (that.state.replyId !== "undefined") {
        data.replyId = that.state.replyId;
      }
      if (that.state.serveCode !== "undefined") {
        data.serveCode = that.state.serveCode;
      }
      return axios({
        method: "POST",
        url:
          that.state.baseURL +
          "solution/healthSolutionGroupData/client/addHealthSolutionGroupDataAndInputData",
        data: data,
        headers: { token: this.state.token },
      })
        .then(function (res) {
          that.state.isNetDone = true;
          let herfValue = that.state.herfValue;
          herfValue = herfValue.replace(
            "idOne=" + that.state?.id,
            "idOne=" + res.data.data?.id
          );
          herfValue = herfValue.replace("status=0", "status=1");
          that.setState({ herfValue: herfValue });
          if (res.data.status === 0) {
            if (that.state.itemId !== "undefined") {
              that.readMessage(1);
            }

            if (that.state.taskId !== "undefined") {
              that.updateFollowedPlanTaskStatus(that.state.taskId);
            }

            // if(res.data.data.score) {groupDataId
            that.setState({
              totalScore: res.data.data.score,
              warnResult: res.data.data.warnResult,
              color: res.data.data.color,
              groupDataId: res.data.data.id,
            });
            // };
            that.onGoNext();
            return res.data.status;
          } else {
            Toast.show(res.data.msg || '提交失败')
          }
        })
        .catch(function (error) {
          console.log(error);
          that.state.isNetDone = true;
        });
    }
  }
  handleSwipe(e) {
    console.log(e);
    const that = this;
    if (that.state.status == "1" && that.state.voList.length === 1) {
      that.onGoNext();
    }
  }
  render() {
    // const settings = {
    //   infinite: false, //一直滑动
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   focusOnSelect: true,
    //   touchMove: this.state.touchMove,
    //   afterChange: (index) => this.onAfterChange(index),
    //   beforeChange: (oldIndex, newIndex) =>
    //     this.onBeforeChange(oldIndex, newIndex),
    // };
    const settings2 = {
      infinite: false, //一直滑动
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: false,
      touchMove: false,
      afterChange: (index) => this.onAfterChange(index),
    };
    return this.state.inputTypeId == 4 || this.state.inputTypeId == 6 || this.state.inputTypeId == 5 ? (
      <div className="">
        {this.state.status == "0" &&
          this.state.view != "view" &&
          this.state.view != "justView" && (
            <Col xs={15}>
              <Hammer onSwipe={this.handleSwipe}>
                <div className="App wrapper">
                  <Slider
                    {...settings2}
                    style={{ borderStyle: "none", minHeight: 400 }}
                    ref={(c) => (this.slider = c)}
                  >
                    {this.state.pageList.length &&
                      this.state.pageList.map((item, index) => (
                        <PageChoose
                          key={index}
                          data={item}
                          end={index == this.state.pageList.length - 1}
                          allState={this.state}
                          onItemTextInput={this.onItemTextInput}
                        />
                      ))}
                  </Slider>
                  {this.ChooseTab2()}
                </div>
              </Hammer>
            </Col>
          )}
        {((this.state.view == "view" && this.state.formPage === "b") ||
          (this.state.formPage == "c" && this.state.status == 1)) && (
            <div className="floating">
              <div>
                <span className="color-333" style={{ fontSize: 14 }}>
                  评测结果
                </span>{" "}
              </div>
              {this.state.name !== "高血压生活干预问卷" && (
                <div
                  className=""
                  style={{ marginTop: 20, color: this.state.color }}
                >
                  <span className="fs28">{this.state.warnResult}</span>
                </div>
              )}
            </div>
          )}
        {(this.state.status == "1" || this.state.formPage === "b") && (
          <Col xs={15}>
            <Hammer onSwipe={this.handleSwipe}>
              <div className="App wrapper">
                <div className="divider"></div>
                {this.state.pageList.length > 0 &&
                  this.state.pageList.map((item, index) => (
                    <PageChoose
                      key={index}
                      data={item}
                      end={index == this.state.pageList.length - 1}
                      allState={this.state}
                      onItemTextInput={this.onItemTextInput}
                    />
                  ))}
              </div>
            </Hammer>{" "}
          </Col>
        )}
      </div>
    ) : this.state.isNetDone ? (
      <div className="">
        {(this.state.view == "view" || (this.state.formPage == "c" && this.state.status == 1)) && (
          <div className="floating">
            <div>
              <span className="color-333" style={{ fontSize: 14 }}>
                评测结果
              </span>{" "}
            </div>
            {this.state.name !== "高血压生活干预问卷" && (
              <div
                className=""
                style={{ marginTop: 20, color: this.state.color }}
              >
                <span className="fs28">{this.state.warnResult}</span>
              </div>
            )}
            <div className="">
              {this.state.name !== "高血压生活干预问卷" && (
                <div className="" style={{ marginTop: 12 }}>
                  <span className="color-333" style={{ fontSize: 14 }}>
                    该问诊表得分:{" "}
                  </span>{" "}
                  <span className="fs14">{this.state.totalScore}分</span>
                </div>
              )}
              {this.state.name == "高血压生活干预问卷" && (
                <div
                  className=""
                  style={{ marginTop: 20, color: this.state.color }}
                >
                  <span className="fs28">{this.state.name}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <Row justify="center">
          {(this.state.view == "view" || this.state.view == "justView" || (this.state.formPage == "c" && this.state.status == 1)) ? (
            this.state.voList.length &&
            this.state.voList.map((item, index) => (
              <div className="list" key={index}>
                <div className="divider"></div>
                <div className="list-content">
                  <div className="title">{item.name}</div>
                  <Choose
                    data={item}
                    onChangeList={this.onChangeList}
                    onChangeTextArea={this.onChangeTextArea}
                    onChangeScore={this.onChangeScore}
                    allState={this.state}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="App wrapper ">
              <div className="header_text">本测评内容仅供参考，不作为诊断意见</div>
              <div style={{ marginBottom: 72 }}>{this.state.voList.length &&
                this.state.voList.map((item, index) => (
                  <div className="" key={index}>
                    <div>
                      <div className="list list-content">
                        <div className="title">{item.name}</div>
                        <Choose
                          data={item}
                          index={index}
                          onChangeList={this.onChangeList}
                          onChangeTextArea={this.onChangeTextArea}
                          onChangeScore={this.onChangeScore}
                          allState={this.state}
                        />
                      </div>
                      <div className="divider"></div>
                    </div>
                  </div>
                ))}</div>
              {this.ChooseTab()}
            </div>
          )}
        </Row>
      </div>
    ) : null;
  }
}

export default Problem;